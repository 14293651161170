import React from 'react';
import styled from 'styled-components';
import { position } from 'polished';
import breakpoints from '../styles/settings/breakpoints';

const Dot = ({ percentage, inView, showDots }) => {
    const getOffset = () => {
        const offset = window.outerWidth > 1023 ? 0.5 : 1.15;
        return offset;
    };

    return (
        <SDotWrapper>
            <SDots style={{ opacity: `${showDots ? '1' : '0'}` }} />
            <SDot
                inview={inView}
                style={{ transform: `translate(-50%, -50%) scale(${inView ? 2.5 : percentage * getOffset()})` }}
            />
        </SDotWrapper>
    );
};

const SDotWrapper = styled.div`
    ${position('absolute', 0, null, null, 0)};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    animation: fadeIn 2s 3.25s ease-in forwards;
    opacity: 0;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;

const SDot = styled.div`
    ${position('fixed', `${window.innerHeight * 0.5}px`, null, null, '50%')};
    width: 160vw;
    height: 160vw;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: ${(props) => (props.inview ? 'transform .36s ease-in-out' : '')};
    background-color: ${(props) => props.theme.colorSecondary};
`;

const SDots = styled.div`
    ${position('fixed', `${window.innerHeight * 0.5}px`, null, null, '50%')};
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);

    ${breakpoints.mega} {
        width: 106px;
        height: 106px;
    }

    &::before,
    &::after {
        ${position('absolute', '50%', null, null, '50%')};
        width: 10px;
        height: 10px;
        background-color: ${(props) => props.theme.colorSecondary};
        border-radius: 50%;
        transform: translate(-50%, -50%);
        content: '';
    }

    &::before {
        transform: translate(-130%, -50%);
        animation: bounce-to-right 1.75s 4s ease-in forwards;
    }

    &::after {
        transform: translate(30%, -50%);
        animation: bounce-to-left 1.75s 4s ease-in forwards;
    }

    @keyframes bounce-to-right {
        0% {
            transform: translate(-130%, -50%);
            width: 10px;
            height: 10px;
        }

        15% {
            transform: translate(-90%, -50%);
            width: 10px;
            height: 10px;
        }

        30% {
            transform: translate(-130%, -50%);
            width: 10px;
            height: 10px;
        }

        45% {
            transform: translate(-90%, -50%);
            width: 10px;
            height: 10px;
        }

        60% {
            transform: translate(-130%, -50%);
            width: 10px;
            height: 10px;
        }

        75% {
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
        }

        100% {
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }

    @keyframes bounce-to-left {
        0% {
            transform: translate(30%, -50%);
            width: 10px;
            height: 10px;
        }

        15% {
            transform: translate(-10%, -50%);
            width: 10px;
            height: 10px;
        }

        30% {
            transform: translate(30%, -50%);
            width: 10px;
            height: 10px;
        }

        45% {
            transform: translate(-10%, -50%);
            width: 10px;
            height: 10px;
        }

        60% {
            transform: translate(30%, -50%);
            width: 10px;
            height: 10px;
        }

        75% {
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
        }

        100% {
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }
`;

export default Dot;
