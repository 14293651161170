import React from 'react';
import styled from 'styled-components';
import { position } from 'polished';
import arrowDownSecondary from '../images/arrow-down-secondary.svg';
import arrowDownPrimary from '../images/arrow-down-primary.svg';

const Arrow = ({ height, type }) => {
    return <SArrow height={height} type={type}></SArrow>;
};

const SArrow = styled.div`
    position: relative;
    height: ${(props) => props.height};

    &::before {
        ${position('absolute', 0, null, null, '50%')}
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
        background-color: ${(props) => (props.type === 'primary' ? 'rgb(159, 134, 111)' : 'rgb(246, 238, 227)')};
        content: '';
    }

    &::after {
        ${position('absolute', null, null, '1px', '50%')}
        width: 7px;
        height: 6px;
        transform: translate(-50%, 100%);
        background-image: ${(props) =>
            props.type === 'primary' ? `url(${arrowDownPrimary})` : `url(${arrowDownSecondary})`};
        background-repeat: none;
        content: '';
    }
`;

export default Arrow;
