const theme = {
    colorWhite: 'rgb(255, 255, 255)',
    colorBlack: 'rgb(0, 0, 0)',

    colorPrimary: 'rgb(159, 134, 111)',
    colorSecondary: 'rgb(246, 238, 227)',

    pageMaxWidth: '1440px',
    containPercentage: '5vw',

    primaryFont: 'helvetica-neue-ext, Helvetica Neue, HelveticaNeue, Helvetica, Arial, ArialMT',
    primaryFontBold: 'helvetica-neue-ext-bold, Helvetica Neue, HelveticaNeue, Helvetica, Arial, ArialMT'
};

export default theme;
