import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../styles/settings/theme';
import { Normalize } from 'styled-normalize';
import { Reset } from 'styled-reset';

const Layout = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <Normalize />
            <Reset />

            <SBase>
                <SMain>{children}</SMain>
            </SBase>
        </ThemeProvider>
    );
};

const SBase = styled.div`
    font-family: ${(props) => props.theme.primaryFont};
    background-color: ${(props) => props.theme.colorBlack};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

const SMain = styled.main``;

export default Layout;
