import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import Stickyfill from 'stickyfilljs';
import Layout from './components/Layout';
import Top from './components/Top';
import Dot from './components/Dot';
import Week from './components/Week';
import Bottom from './components/Bottom';
import 'intersection-observer';
import { useScrollPercentage } from 'react-scroll-percentage';
import { useInView } from 'react-intersection-observer';
import image2 from './images/photo-2.jpg';
import image3 from './images/photo-3.jpg';
import image4 from './images/photo-4.jpg';
import image5 from './images/photo-5.jpg';

function App() {
    const [ref, percentage] = useScrollPercentage({
        threshold: 0.1
    });

    const [inviewRef, inView] = useInView({
        threshold: 0.25,
        rootMargin: '1000px 0px 0px 0px'
    });

    const [topInviewRef, topInView] = useInView({});

    useEffect(() => {
        var elements = document.querySelectorAll('.js-sticky-element');
        Stickyfill.add(elements);
    }, []);

    return (
        <div className="App">
            <Layout>
                <ParallaxProvider>
                    <SWrapper ref={ref}>
                        <div ref={topInviewRef}>
                            <Top />
                        </div>
                        <Dot percentage={percentage} inView={inView} showDots={topInView} />

                        <Week
                            content={{
                                weekValue: '10',
                                title: 'Henkie',
                                description: {
                                    title: 'Projectnaam',
                                    text:
                                        '“We hebben altijd gezegd dat onze moeders voor de grap een kaart met de projectnaam zouden ontvangen. Ach, waarom ook niet iedereen?”'
                                }
                            }}
                            textColor="rgb(246, 238, 227)"
                        />
                        <Week
                            content={{
                                weekValue: '12',
                                title: 'Robin',
                                description: {
                                    title: 'Jongen/meisje',
                                    text:
                                        '“Handig zo’n naam als je nog niet weet wat het gaat worden. Ook leuk als knipoog naar de mascotte van Rodesk.”'
                                },
                                image: {
                                    src: image2,
                                    alignment: 'center'
                                }
                            }}
                            textColor="rgb(246, 238, 227)"
                        />
                        <div ref={inviewRef}>
                            <Week
                                content={{
                                    weekValue: '13',
                                    title: 'ATHINA',
                                    description: {
                                        title: 'Marathon Athene',
                                        text:
                                            '“Na het finishen van onze eerste gezins-marathon roept een Griekse ober dat we ons kind moeten vernoemen naar een Griekse god(in)!”'
                                    },
                                    image: {
                                        src: image3,
                                        alignment: 'left'
                                    }
                                }}
                                textColor="rgb(159, 134, 111)"
                            />
                        </div>

                        <Week
                            content={{
                                weekValue: '27',
                                title: 'BROOKLYN / QUEENS',
                                description: {
                                    title: 'New York City',
                                    text:
                                        '“We willen zo graag een knipoog maken naar de stad waar we zoveel goede momenten hebben gedeeld. Maar, mhhh… voor een kind?”'
                                },
                                image: {
                                    src: image4,
                                    alignment: 'left'
                                }
                            }}
                            backgroundColor="rgb(246,238,227)"
                            textColor="rgb(159, 134, 111)"
                        />
                    </SWrapper>
                    <Week
                        content={{
                            weekValue: '28',
                            title: ':)',
                            description: {
                                title: 'Soms weet je het gewoon',
                                text:
                                    '“Laura roept een naam. Deze voelt in een keer goed! We vinden het chique, kort, mooi geschreven en kennen niemand die deze naam draagt (behalve de vrouw van Superman).”'
                            },
                            image: {
                                src: image5,
                                alignment: 'left'
                            }
                        }}
                        backgroundColor="rgb(246,238,227)"
                        textColor="rgb(159, 134, 111)"
                    />
                </ParallaxProvider>

                <Bottom />
            </Layout>
        </div>
    );
}

const SWrapper = styled.div`
    position: relative;
`;

export default App;
