import React, { useEffect } from 'react';
import styled from 'styled-components';
import { position, fluidRange } from 'polished';
import { useScrollPercentage } from 'react-scroll-percentage';
import breakpoints from '../styles/settings/breakpoints';
import photo from '../images/photo-1.jpg';
import Arrow from './Arrow';

const Top = () => {
    const [arrowRef, arrowPercentage] = useScrollPercentage({
        threshold: 0
    });

    useEffect(() => {
        disableScrolling();

        setTimeout(() => {
            enableScrolling();
        }, 6000);
    }, []);

    const disableScrolling = () => {
        var x = window.scrollX;
        var y = window.scrollY;

        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    };

    const enableScrolling = () => {
        window.onscroll = function () {};
    };

    return (
        <STop>
            <STopTitle>
                <strong>Rotterdam</strong> – 16 augustus 2019 <br />
                <span>“Het begin van een mooie lange trip.”</span>
            </STopTitle>

            <STopArrow ref={arrowRef}>
                <div style={{ opacity: arrowPercentage === 0 ? 0.7 : 1 - arrowPercentage * 1.3 }}>
                    <Arrow height="170px" type="secondary" />
                </div>
            </STopArrow>

            <STopImage src={photo} alt="baby" />
        </STop>
    );
};

const STop = styled.div`
    position: relative;
    height: ${window.innerHeight}px;
`;

const STopTitle = styled.p`
    ${position('absolute', '50%', null, null, '50%')}
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: ${(props) => props.theme.colorSecondary};
    line-height: 1.6;
    animation: topTitle 3s 1s ease-in-out forwards;
    opacity: 0;

    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '17px',
            toSize: '19px'
        },
        '320px',
        '1440px'
    )}

    strong {
        font-family: ${(props) => props.theme.primaryFontBold};
    }

    span {
        opacity: 0;
        animation: topTitleFadeIn 3s 2s ease-in forwards;
    }

    @keyframes topTitle {
        0% {
            top: 50%;
            opacity: 0;
            transform: translate(-50%, -50%);
        }

        50% {
            top: 50%;
            opacity: 1;
            transform: translate(-50%, -50%);
        }

        80% {
            top: 50%;
            opacity: 1;
            transform: translate(-50%, -50%);
        }

        100% {
            top: 25%;
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }

    @keyframes topTitleFadeIn {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }
`;

const STopArrow = styled.div`
    ${position('absolute', '60%', null, null, '50%')}
    transform: translateX(-50%);
    opacity: 0;
    animation: topArrow 1s 5.5s ease-in forwards;

    @keyframes topArrow {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;

const STopImage = styled.img`
    ${position('absolute', '90%', null, null, '50%')}
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 320px;
    opacity: 0;
    transform: translateX(-50%);
    animation: topArrow 1s 5.5s ease-in forwards;

    @keyframes topArrow {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 0.5;
        }
    }

    ${breakpoints.large} {
        width: auto;
    }
`;

export default Top;
